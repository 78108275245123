import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";

const title = "Contact";
const metaDescription = "Contact form for ScData.io. Have a question or an issue with the site?  Contact us here.";
const metaUrl = "https://scdata.io/contact/";
//const metaImage = "https://scdata.io/OgImg/scdata.jpg";
const metaImage = "";
export default () => (
  <Layout>
    <Helmet>
      <title>{`${title} - ScData.io`}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="2445424502355895" />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="675" />
      <meta property="og:image:alt" content={title} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@scdata" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
      <html lang="en" />
    </Helmet>
    <div id="content" className="container">
      <div className="layoutGrid">
        <div className="card">
          <h1 className="h1">Contact ScData.io</h1>
          <p>Have a question or an issue with the site? We'd love to hear from you.</p>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="bot-field" />
            <p>
              <Label>Name</Label>
              <input type="text" name="name" />
            </p>
            <p>
              <Label>Email</Label>
              <input type="email" name="email" />
            </p>
            <p>
              <Label>Message: </Label>
              <textarea spellCheck="true" rows="5" cols="50" name="message"></textarea>
            </p>
            <p>
              <button type="submit">Send</button>
            </p>
          </form>
        </div>
      </div>
    </div>
  </Layout>
);

const Label = styled.label`
  display: block;
`;
